export const API_HOST = process.env.REACT_APP_API_URL;

export const API_SOCKET_HOST = process.env.REACT_APP_API_URL;

export const API_AUTH_LOGIN = API_HOST + "/login";
export const API_AUTH_LOGOUT = API_HOST + "/logout";
export const API_AUTH_CHECK = API_HOST + "/check-auth";
export const API_AUTH_UPDATE_TOKEN = API_HOST + "/update-token";

export const API_ITEM_LIST = API_HOST + "/items";
export const API_PUT_OR_GET_FROM_BANK = API_HOST + "/items-put-or-get";
export const API_CRAFT_ITEM = API_HOST + "/craft-item";
export const API_EXCHANGE_ITEM = API_HOST + "/exchange-item";
export const API_DICTIONARY = API_HOST + "/dictionary";
export const API_SERVER_TIME = API_HOST + "/get-server-time";
export const API_DUNGEONS = API_HOST + "/get-dungeons";

export const API_DIALOG = API_HOST + "/dialog";
export const API_DIALOG_STATE = API_HOST + "/dialog-state";
// Group
export const API_GROUP_INFO = API_HOST + "/group-info";
export const API_GROUP_RATE = API_HOST + "/group-rate";
