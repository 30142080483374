import styles from "./ActionModalWithButtons.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

function ActionModalWithButtons({
  buttons,
  children,
  info,
  onClose,
  toTop,
  withBkg = false,
}: any) {
  return (
    <>
      <div className={`${styles.ActionContainer}`}>
        <div
          className={`${styles.ActionWrapper} ${
            toTop ? styles.ActionWrapperTop : ""
          }`}
        >
          <div
            className={`${styles.ActionContent} ${withBkg ? "bkg-modal" : ""} ${
              !buttons.length ? styles.ActionContentRound : ""
            }`}
          >
            <div>{children}</div>
            <FontAwesomeIcon
              className={styles.CloseDialog}
              icon={faClose}
              onClick={onClose}
            />
            {info && (
              <div className={`${styles.ActionContentInfo}`}>{info}</div>
            )}
          </div>

          <div className={styles.ActionButtons}>
            {buttons.map((btn: any, btnIndex: number) => {
              return (
                <button
                  key={btnIndex}
                  onClick={e => {
                    e.stopPropagation();
                    btn.action && btn.action();
                  }}
                >
                  {btn.title}
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default ActionModalWithButtons;
